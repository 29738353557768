<!-- eslint-disable -->
<template>
    <div class="contact-data">
        <Progress state="2" />
        <div class="mt-10 sm:mt-0">
            <form @submit.prevent="submit" class="space-y-12">
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    <!-- SIDE -->
                    <div class="md:col-span-1">
                        <h2
                            class="text-lg font-semibold leading-6 text-emerald-700"
                        >
                            Daten der pflegebedürftigen Person
                        </h2>
                        <p class="mt-1 text-sm text-gray-600">
                            Diese Daten benötigen wir für die Antragstellung bei Ihrer Pflegekasse und für den Versand der Pflegehilfsmittel.
                        </p>
                    </div>
                    <!-- CARD -->
                    <div class="mt-5 md:mt-0 md:col-span-2">
                        <div class="shadow bg-white sm:rounded-md">
                            <div class="px-4 py-5 sm:p-6">
                                <h3
                                    class="text-base font-semibold text-gray-900"
                                >
                                    Daten der pflegebedürftigen Person
                                </h3>
                                <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-6">
                                        <div class="flex mt-4 space-x-4">
                                            <div class="flex items-center">
                                                <label
                                                    class="block text-sm text-gray-700"
                                                    :class="{ 'focus:ring-red-300 text-red-400': v$.form.insure_title.$errors.length > 0, 'text-green-500': !v$.form.insure_title.$silentErrors.length, }"
                                                >
                                                    <input
                                                        class="form-radio focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300"
                                                        type="radio"
                                                        id="insure_title"
                                                        name="insure_title"
                                                        value="Herr"
                                                        v-model="form.insure_title"
                                                        :class="{ 'ring-red-300': v$.form.insure_title.$errors.length > 0 }"
                                                    />
                                                    Herr
                                                </label>
                                            </div>
                                            <div class="flex items-center">
                                                <label
                                                    class="ml-3 block text-sm text-gray-700"
                                                    :class="{ 'focus:ring-red-300 text-red-400': v$.form.insure_title.$errors.length > 0, 'text-green-500': !v$.form.insure_title.$silentErrors.length, }"
                                                >
                                                    <input
                                                        class="form-radio focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300"
                                                        type="radio"
                                                        id="insure_title"
                                                        name="insure_title"
                                                        value="Frau"
                                                        v-model="form.insure_title"
                                                        :class="{ 'ring-red-300': v$.form.insure_title.$errors.length > 0 }"
                                                    />
                                                    Frau
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-6 sm:col-span-3">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ 'text-red-400': v$.form.insure_firstname.$errors.length > 0, 'text-green-500': !v$.form.insure_firstname.$silentErrors.length, }"
                                            for="insure_firstname"
                                            >Vorname *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="insure_firstname"
                                            id="insure_firstname"
                                            v-model.trim="form.insure_firstname"
                                        />
                                    </div>
                                    <div class="col-span-6 sm:col-span-3">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ 'text-red-400': v$.form.insure_lastname.$errors.length > 0, 'text-green-500': !v$.form.insure_lastname.$silentErrors.length, }"
                                            for="insure_lastname"
                                            >Nachname *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="insure_lastname"
                                            id="insure_lastname"
                                            v-model.trim="form.insure_lastname"
                                        />
                                    </div>
                                    <div class="col-span-6 sm:col-span-3">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ 'text-red-400': v$.form.insure_street.$errors.length > 0, 'text-green-500': !v$.form.insure_street.$silentErrors.length, }"
                                            for="insure_street"
                                            >Straße Hausnummer *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="insure_street"
                                            id="insure_street"
                                            v-model.trim="form.insure_street"
                                        />
                                    </div>
                                    <div class="col-span-2 sm:col-span-1">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ '': true, 'text-red-400': v$.form.insure_plz.$errors.length > 0, 'text-green-500': !v$.form.insure_plz.$silentErrors.length, }"
                                            for="insure_plz"
                                            >PLZ *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="insure_plz"
                                            id="insure_plz"
                                            v-model="form.insure_plz"
                                        />
                                    </div>
                                    <div class="col-span-4 sm:col-span-2">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ '': true, 'text-red-400': v$.form.insure_city.$errors.length > 0, 'text-green-500': !v$.form.insure_city.$silentErrors.length, }"
                                            for="insure_city"
                                            >Stadt *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="insure_city"
                                            id="insure_city"
                                            v-model="form.insure_city"
                                        />
                                    </div>
                                    <div class="col-span-6 p-4 bg-emerald-100 rounded-md">
                                        <p class="text-base font-semibold text-emerald-900"
                                            >Abweichende Lieferadresse
                                        </p>
                                        <div class="flex">
                                            <input
                                                class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                type="checkbox"
                                                name="different_delivery_address"
                                                id="different_delivery_address"
                                                v-model="different_delivery_address"
                                            />
                                          <label for="different_delivery_address" class="ml-2 text-sm text-emerald-700">
                                             Bitte senden Sie die Pflegehilfmittel an eine andere Adresse.
                                          </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- CARD -->
                    <div 
                        class="col-span-1"
                        :class="{ 'hidden': !different_delivery_address }"
                        ></div><!-- HACK FOR NON-FUNCTIONING col-start-2 -->
                    <div
                        class="mt-5 md:mt-0 md:col-span-2 col-start-2" 
                        :class="{ 'hidden': !different_delivery_address }"
                    >
                        <div class="shadow bg-white sm:rounded-md">
                            <div class="px-4 py-5 sm:p-6">
                                <h3
                                    class="text-base font-semibold text-gray-900"
                                >
                                    Abweichende Lieferadresse
                                </h3>
                                <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-6">
                                        <div class="flex mt-4 space-x-4">
                                            <div class="flex items-center">
                                                <label
                                                    class="block text-sm text-gray-700"
                                                    :class="{ 'focus:ring-red-300 text-red-400': v$.form.delivery_title.$errors.length > 0, 'text-green-500': !v$.form.delivery_title.$silentErrors.length, }"
                                                >
                                                    <input
                                                        class="form-radio focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300"
                                                        type="radio"
                                                        id="delivery_title"
                                                        name="delivery_title"
                                                        value="Herr"
                                                        v-model="form.delivery_title"
                                                        :class="{ 'ring-red-300': v$.form.delivery_title.$errors.length > 0 }"
                                                    />
                                                    Herr
                                                </label>
                                            </div>
                                            <div class="flex items-center">
                                                <label
                                                    class="ml-3 block text-sm text-gray-700"
                                                    :class="{ 'focus:ring-red-300 text-red-400': v$.form.delivery_title.$errors.length > 0, 'text-green-500': !v$.form.delivery_title.$silentErrors.length, }"
                                                >
                                                    <input
                                                        class="form-radio focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300"
                                                        type="radio"
                                                        id="delivery_title"
                                                        name="delivery_title"
                                                        value="Frau"
                                                        v-model="form.delivery_title"
                                                        :class="{ 'ring-red-300': v$.form.delivery_title.$errors.length > 0 }"
                                                    />
                                                    Frau
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-6 sm:col-span-3">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ 'text-red-400': v$.form.delivery_firstname.$errors.length > 0, 'text-green-500': !v$.form.delivery_firstname.$silentErrors.length, }"
                                            for="delivery_firstname"
                                            >Vorname *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="delivery_firstname"
                                            id="delivery_firstname"
                                            v-model.trim="form.delivery_firstname"
                                        />
                                    </div>
                                    <div class="col-span-6 sm:col-span-3">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ 'text-red-400': v$.form.delivery_lastname.$errors.length > 0, 'text-green-500': !v$.form.delivery_lastname.$silentErrors.length, }"
                                            for="delivery_lastname"
                                            >Nachname *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="delivery_lastname"
                                            id="delivery_lastname"
                                            v-model.trim="form.delivery_lastname"
                                        />
                                    </div>
                                    <div class="col-span-6 sm:col-span-3">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ 'text-red-400': v$.form.delivery_street.$errors.length > 0, 'text-green-500': !v$.form.delivery_street.$silentErrors.length, }"
                                            for="delivery_street"
                                            >Straße Hausnummer *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="delivery_street"
                                            id="delivery_street"
                                            v-model.trim="form.delivery_street"
                                        />
                                    </div>
                                    <div class="col-span-2 sm:col-span-1">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ '': true, 'text-red-400': v$.form.delivery_plz.$errors.length > 0, 'text-green-500': !v$.form.delivery_plz.$silentErrors.length, }"
                                            for="delivery_plz"
                                            >PLZ *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="delivery_plz"
                                            id="delivery_plz"
                                            v-model="form.delivery_plz"
                                        />
                                    </div>
                                    <div class="col-span-4 sm:col-span-2">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ '': true, 'text-red-400': v$.form.delivery_city.$errors.length > 0, 'text-green-500': !v$.form.delivery_city.$silentErrors.length, }"
                                            for="delivery_city"
                                            >Stadt *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="delivery_city"
                                            id="delivery_city"
                                            v-model="form.delivery_city"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    <!-- SIDE -->
                    <div class="md:col-span-1">
                        <h2
                            class="text-lg font-semibold leading-6 text-emerald-700"
                        >
                            Kostenübernahme nur mit Pflegegrad
                        </h2>
                        <p class="mt-1 text-sm text-gray-600">
                            Um Ihre Pflegehilfsmittel kostenlos zu erhalten, benötigen Sie einen Pflegegrad. Falls noch keine Pflegegrad festgestellt wurde, empfehlen wir, beides gleichzeitig zu beantragen.
                        </p>
                    </div>
                    <!-- CARD -->
                    <div class="mt-5 md:mt-0 md:col-span-2">
                        <div class="shadow bg-white sm:rounded-md">
                            <div class="px-4 py-5 sm:p-6">
                                <h3
                                    class="text-base font-semibold text-gray-900"
                                >
                                    Angaben zum Pflegegrad
                                </h3>
                                <div class="grid grid-cols-6 gap-6 mt-4">
                                    <div class="col-span-6 sm:col-span-3">
                                        <label
                                            class="block text-sm text-gray-700"
                                            for="carelevel"
                                            >
                                            Pflegegrad
                                        </label>
                                        <select
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            name="carelevel"
                                            v-model="form.carelevel"
                                        >
                                            <option value="1">
                                                Pflegegrad 1
                                            </option>
                                            <option value="2">
                                                Pflegegrad 2
                                            </option>
                                            <option value="3">
                                                Pflegegrad 3
                                            </option>
                                            <option value="4">
                                                Pflegegrad 4
                                            </option>
                                            <option value="5">
                                                Pflegegrad 5
                                            </option>
                                            <option value="6">
                                                Kein Pflegegrad vorhanden
                                            </option>
                                        </select>
                                    </div>
                                    <p class="col-span-6 sm:col-span-3 p-4 text-sm text-emerald-700 bg-emerald-100 rounded-md" :class="{ 'hidden': form.carelevel !== '6' }">
                                        Wenn Sie keinen Pflegegrad haben, tragen Sie die Kosten für die Pflegehilfsmittel selbst. Die Rechnung erhalten Sie mit der Lieferung.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    <!-- SIDE -->
                    <div class="md:col-span-1">
                        <h2
                            class="text-lg font-semibold leading-6 text-emerald-700"
                        >
                            Rückfragen zu Ihrer Elder Aid Box
                        </h2>
                        <p class="mt-1 text-sm text-gray-600">
                            Ihre Kontaktdaten benötigen wir für eventuelle Rückfragen und um Sie über den Status Ihrer Sendung zu informieren.
                        </p>
                    </div>
                    <!-- CARD -->
                    <div class="mt-5 md:mt-0 md:col-span-2">
                        <div class="shadow bg-white sm:rounded-md">
                            <div class="px-4 py-5 sm:p-6">
                                <h3
                                    class="text-base font-semibold text-gray-900"
                                >
                                    Kontaktdaten für eventuelle Rückfragen
                                </h3>
                                <div class="grid grid-cols-6 gap-6 mt-4">
                                    <div class="col-span-6 sm:col-span-3">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ 'text-red-400': v$.form.insure_mobile.$errors.length > 0, 'text-green-500': !v$.form.insure_mobile.$silentErrors.length, }"
                                            for="insure_mobile"
                                            >Telefonnummer *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="tel"
                                            name="insure_mobile"
                                            id="insure_mobile"
                                            v-model.trim="form.insure_mobile"
                                        />
                                    </div>
                                    <div class="col-span-6 sm:col-span-3">
                                        <label
                                            class="block text-sm text-gray-700"
                                            :class="{ 'text-red-400': v$.form.insure_email.$errors.length > 0, 'text-green-500': !v$.form.insure_email.$silentErrors.length, }"
                                            for="insure_email"
                                            >E-Mailadresse *</label
                                        >
                                        <input
                                            class="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            type="text"
                                            name="insure_email"
                                            id="insure_email"
                                            v-model.trim="form.insure_email"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 col-start-2 mt-6 space-y-4">
                        <div class="flex items-start">
                            <div class="flex items-center h-5">
                                <input class="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300 rounded" type="checkbox" name="provider_change" id="provider_change" v-model="form.provider_change">
                            </div>
                            <div class="ml-3 text-sm">
                            <label for="provider_change" class="text-gray-500" :class="{ 'text-red-500': v$.form.provider_change.$errors.length > 0 }">
                                Ich werde zur Zeit von einem anderen Versorger beliefert. Ich möchte aber in Zukunft von Elder Aid beliefert werden.
                            </label>
                            </div>
                        </div>
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input class="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300 rounded" type="checkbox" name="privacy" id="privacy" v-model="form.privacy">
                                </div>
                                <div class="ml-3 text-sm">
                                <label for="privacy" class="text-gray-500" :class="{ 'text-red-500': v$.form.privacy.$errors.length > 0 }">
                                    Ich bin mit den <a class="underline text-blue-600" href="https://www.elder-aid.de/datenschutz" target="_blank">Datenschutzhinweisen</a> und der Verarbeitung meiner Daten zu Bearbeitungs-, Beratungs- und Marketingzwecken sowie der Kontaktaufnahme per Telefon und E-Mail einverstanden. Ein Widerruf ist jederzeit möglich.
                                </label>
                            </div>
                        </div>
                        <div class="flex justify-between px-4 py-3 sm:px-6">
                            <button class="inline-flex justify-center py-2 px-4 border-2 border-emerald-600 shadow-sm text-sm font-semibold rounded-md text-emerald-600  hover:bg-emerald-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500" onclick="history.go(-1); return false;" >Zurück</button>
                            <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"  type="submit" :disabled="submitStatus === 'PENDING'" >Weiter</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<!-- eslint-enable -->

<script>
    import { mapGetters } from "vuex";
    import { email, required } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";
    import Progress from "./Progress.vue";
    import { de } from "date-fns/locale";

    export default {
      components: {
        Progress,
      },
      setup() {
        return { v$: useVuelidate() };
      },
      data: () => ({
        de,
        submitStatus: "",
        different_delivery_address: false,
        form: {
          insure_title: "",
          insure_firstname: "",
          insure_lastname: "",
          insure_street: "",
          insure_plz: "",
          insure_city: "",
          insure_mobile: "",
          insure_email: "",
          delivery_type: "",
          delivery_title: "",
          delivery_firstname: "",
          delivery_lastname: "",
          delivery_street: "",
          delivery_plz: "",
          delivery_city: "",
          carelevel: "",
          provider_change: "",
          privacy: "",
        },
      }),
      validations() {
        return {
          form: {
            insure_title: { required },
            insure_firstname: { required },
            insure_lastname: { required },
            insure_street: { required },
            insure_plz: { required },
            insure_city: { required },
            insure_mobile: { required },
            insure_email: { required, email },
            carelevel: {},
            provider_change:  {},
            privacy: { required },

            delivery_type: { },
            delivery_title: { required: this.different_delivery_address ? required : false },
            delivery_firstname: { required: this.different_delivery_address ? required : false },
            delivery_lastname: { required: this.different_delivery_address ? required : false },
            delivery_street: { required: this.different_delivery_address ? required : false },
            delivery_plz: { required: this.different_delivery_address ? required : false },
            delivery_city: { required: this.different_delivery_address ? required : false },
          },
        };
      },
      methods: {
        submit() {
          this.v$.$touch();
          if (!this.v$.$invalid) {
            this.submitStatus = "PENDING";
            const form = {
              ...this.form,
              email: this.form.insure_email,
              delivery_type: this.different_delivery_address ? 2 : 1,
            };
            form.provider_change = form.provider_change === true ? 1 : 0;

            if (this.different_delivery_address === false) {
              form.delivery_title = form.insure_title;
              form.delivery_firstname = form.insure_firstname;
              form.delivery_lastname = form.insure_lastname;
              form.delivery_street = form.insure_street;
              form.delivery_plz = form.insure_plz;
              form.delivery_city = form.insure_city;
            }

           this.$store.dispatch("user/create", {
              data: form,
              cb: (a) => {
                if (a === true) {
                  this.$store.dispatch("cart/checkout", {
                    data: this.products,
                    cb: (a) => {
                      if (a === true) {
                        this.submitStatus = "OK";
                        this.$router.push("/unterschrift");
                      } else {
                        console.log("NEED ERROR HANDLING 1");
                      }
                    },
                  });
                } else {
                  console.log("NEED ERROR HANDLING");
                }
              },
            });
          }
        },
      },
      computed: {
        ...mapGetters("cart", {
          products: "cartProducts",
          minPrice: "cartMinPrice",
        }),
        ...mapGetters("user", {
          user: "data",
        }),
      },
      mounted() {
        if (!this.minPrice) {
          this.$router.push({ name: "ProductSelection" });
          this.$store.dispatch("user/clearToken");
          return;
        }
        this.form = { ...this.form, ...this.$store.getters["user/data"] };
        this.delivery_type = this.different_delivery_address === 2 ? true : false;
        this.form.provider_change = this.form.provider_change === 1 ? true : false;
      },
    };
</script>

<style lang="postcss">
    .mec-chooser .vs__dropdown-toggle {
      @apply relative flex justify-between py-2 px-3 shadow-sm sm:text-sm border-gray-300 rounded-md border focus-within:ring-cyan-500 focus-within:ring-2;
    }

    .mec-chooser .vs__dropdown-menu {
      @apply absolute py-2 px-3 bg-white max-h-64 max-w-max overflow-scroll shadow-sm sm:text-sm border-gray-300 rounded-md border focus-within:ring-cyan-500 focus-within:ring-2;
    }

    .mec-chooser {
      @apply mt-1;
    }
    .mec-chooser .vs__search,
    .vs__search:focus {
      border: none;
      padding: 0;
      font-size: 0.875rem;
      line-height: 1.25rem;
      @apply focus:ring-white;
    }

    .mec-chooser .vs__clear,
    .mec-chooser .vs__open-indicator {
      fill: gray-300;
    }

    .mec-chooser .vs__selected-options {
      flex-wrap: nowrap;
    }

    span.vs__selected {
      position: absolute;
      @apply w-11/12 text-sm;
    }

    .vs__dropdown-option--highlight {
      @apply bg-gray-100;
    }

    .vs__actions {
      @apply flex justify-center items-center;
    }
</style>
